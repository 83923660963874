import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col} from 'react-bootstrap'
import SEO from "../components/seo"

const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="Military" />
        <div >
            <Image alt="military" filename="military-ban.png"  />
        </div>
        <Container>
            <Row className="servicesPage">
                <h2 className="servicePageHead"> Military Product Design Services </h2>
            </Row>
            <hr className="embhr" />
            <Row>
                <Col sm={12} lg={6} className="servicePagePara">
                Technology is greatly affecting the present day and future operations of our nation’s military. Although there are a host of advances in technology, there still remains new challenges and with Trace Width technology we can help you discover powerful, proven solutions in Military technology and innovation. From land, sea or air, the difference the right technology can make is critical and our engineers and developers have the expertise to design and develop the next level in Military innovations.


                </Col>
                <Col></Col>
            </Row>
            </Container>
            
        <div className="headerForm"> 
            <br/>
            
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

